const Menu = [
  {
    title: "Locations manager",
    value: "location_manager",
    icon: "business",
    link: "/location-management",
  },
  {
    title: "Users manager",
    value: "user_manager",
    icon: "person",
    link: "/user-management",
  },
  // {
  //   title: 'Reload status',
  //   value: 'reload_status',
  //   icon: 'refresh',
  //   link: '/'
  // },
  // {
  //   title: 'Login',
  //   value: 'login',
  //   icon: 'login',
  //   link: '/login'
  // },
  {
    title: "Logout",
    value: "logout",
    icon: "logout",
    link: "/logout",
  },
];

// reorder menu
Menu.forEach((item) => {
  // console.log('menu', item)
});

export default Menu;
