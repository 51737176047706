<template>
  <v-container grid-list-lg id="main">
    <div id="container">
      <v-layout row>
        <v-flex grow>
          <BreadcrumbsComponent :items="items" />
        </v-flex>
        <v-flex shrink>
          <v-btn color="primary" @click="(isCreate = true), openFloorModal()"
            >Add Floor</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <h1 id="floor-title">
        {{ $t("manageInfoArea.floorList") }} - BLOCK {{ blockName }}
      </h1>
      <v-divider></v-divider>
      <v-layout wrap rows>
        <v-flex
          md3
          xs3
          v-for="floor in floors"
          :key="floor.index"
          class="text-md-center text-sx-center"
        >
          <!--Status = 1 => Safe | Status = 2 => Fall detected-->
          <v-card
            :id="floor.status <= 1 ? floor.id_floor : cardId"
            :class="floor.status <= 1 ? '' : 'alert-glowing'"
            @click.native="onClickFloors(floor)"
          >
            <v-card-title class="primary">
              <v-layout align-center column>
                <div slot="label">
                  <h3 class="white--text">
                    {{ $t("manageInfoArea.floor") }} {{ floor.name_floor }}
                  </h3>
                </div>
              </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <v-card flat tile class="d-flex">
              <v-img
                :src="require('@/assets/images/floor-icon.svg')"
                aspect-ratio="1.1"
                contain
                class="grey lighten-2"
              >
                <v-layout slot="placeholder" align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-layout>
              </v-img>
            </v-card>
            <v-alert
              :value="true"
              color="success"
              :icon="floor.status <= 1 ? 'check_circle' : 'priority_high'"
              outline
              @click.stop="openConfirmUpdateStatusModal(floor)"
            >
              <!-- using click.stop to prevent firing click event on parent -->
              <v-btn
                @click.stop="(isCreate = false), openFloorModal(floor)"
                absolute
                color="orange darken-1"
                class="white--text"
                fab
                small
                right
                top
              >
                <v-icon>edit</v-icon>
              </v-btn>
              {{ floor.status <= 1 ? safeMessage : waringMessage }}
            </v-alert>
          </v-card>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </div>
    <footer-component id="footer"></footer-component>

    <vue-js-modal
      name="floor-modal"
      @before-open="beforeOpenFloorModal"
      :click-to-close="false"
    >
      <v-container>
        <v-layout align-center column>
          <h1>
            {{
              isCreate
                ? "Add Floor"
                : "Update Floor " + floorOnEditing.floorName
            }}
          </h1>
        </v-layout>
        <v-form
          ref="floorForm"
          v-on="{ submit: isCreate ? addFloor : updateFloor }"
          onsubmit="return false;"
          v-model="isValid"
        >
          <v-text-field
            name="floorName"
            :label="labelFloorName"
            type="text"
            v-model="model.floorName"
            :rules="floorNameRules"
            class="mb-3"
          ></v-text-field>
          <v-layout justify-space-between row>
            <v-layout row>
              <v-btn
                color="red"
                @click="$modal.hide('floor-modal')"
                :disabled="loading"
                >Close</v-btn
              >
              <v-btn
                color="warning"
                @click="$refs.floorForm.reset()"
                :disabled="loading"
                >Clear All</v-btn
              >
            </v-layout>
            <v-btn
              type="submit"
              color="success"
              :loading="loading"
              :disabled="!isValid"
              >Confirm</v-btn
            >
          </v-layout>
        </v-form>
      </v-container>
    </vue-js-modal>

    <vue-js-modal
      name="confirm-modal"
      height="auto"
      @before-open="beforeOpenFloorModal"
    >
      <v-container>
        <v-layout align-center column>
          <h1>{{ "Update Status" }}</h1>
        </v-layout>
        <v-card-text>
          {{ $t("text.confirmUpdateStatusInFloor") }}
        </v-card-text>
        <v-layout justify-space-between row>
          <v-btn @click="$modal.hide('confirm-modal')" :disabled="loading"
            >Close</v-btn
          >
          <v-btn color="red" :loading="loading" @click="updateStatusInFloor"
            >Confirm</v-btn
          >
        </v-layout>
      </v-container>
    </vue-js-modal>
  </v-container>
</template>

<script>
import FooterComponent from "./containers/Footer";
import BreadcrumbsComponent from "./containers/Breadcrumbs";
import Swal from "sweetalert2";

export default {
  name: "FloorsManagement",
  components: {
    FooterComponent,
    BreadcrumbsComponent,
  },
  data() {
    return {
      safeMessage: "Everything is good.",
      waringMessage: "Fall Detected.",
      cardId: "card-floor",
      items: [
        {
          text: "Blocks",
          disabled: false,
          href: "/#/location-management",
        },
        {
          text: "Floors",
          disabled: true,
          href: "/",
        },
      ],
      loading: false,
      isValid: true,
      isCreate: true,
      model: {},
      floorOnEditing: {},
      labelFloorName: this.$t(`manageInfoArea.floorName`),
      floorNameRules: [
        (v) => !!v || this.$t(`rulesMessages.floorNameRequired`),
      ],
    };
  },
  computed: {
    floors() {
      return this.$store.state.floors.all;
    },
    currentBlock() {
      return this.$route.params.block;
    },
    blockName() {
      return this.$store.state.blocks.currentBlock.name_block;
    },
  },
  created() {
    this.$store.dispatch("floors/listFloorsByBlock", this.currentBlock);
  },
  methods: {
    onClickFloors(floor) {
      this.$router.push({
        name: "AreaManagement",
        params: { floor: floor.id_floor },
      });
    },

    openFloorModal(floorModel) {
      this.$modal.show("floor-modal", {
        ...floorModel,
      });
    },

    openConfirmUpdateStatusModal(floorModel) {
      if (floorModel.status > 1) {
        this.$modal.show("confirm-modal", {
          ...floorModel,
        });
      }
    },

    beforeOpenFloorModal(event) {
      this.model = event.params;
      this.floorOnEditing = event.params;
    },

    addFloor() {
      this.$store
        .dispatch("floors/addFloor", {
          blockId: this.currentBlock,
          floorName: this.model.name_floor,
        })
        .then((resolve) => {
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          this.$refs.floorForm.reset();
          this.$modal.hide("floor-modal");
        })
        .catch((reject) => {
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Adding floor failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
        });
    },

    updateFloor() {
      this.loading = true;
      this.$store
        .dispatch("floors/updateFloor", {
          floorId: this.model.id_floor,
          floorName: this.model.name_floor,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          this.$refs.floorForm.reset();
          this.$modal.hide("floor-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Updating floor failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
          // this.$refs.floorForm.reset()
          // this.$modal.hide('floor-modal')
        });
    },

    updateStatusInFloor() {
      this.loading = true;
      this.$store
        .dispatch("alarmDevices/updateStatusInFloor", {
          floorId: this.model.id_floor,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          this.$modal.hide("confirm-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Update Status In Floor failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
        });
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 100px;
}

.disabled {
  color: grey;
  pointer-events: none;
}

#floor-title {
  text-align: center;
  margin: 10px;
}

#card-floor {
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
</style>
