// noinspection NpmUsedModulesInstalled
import {
  getListAlarmDeviceByArea,
  updateAlarmDeviceNormalStatusByBlock,
  updateAlarmDeviceNormalStatusByFloor,
  updateAlarmDeviceNormalStatusByArea,
  updateAlarmDeviceNormalStatus,
} from "@/api/location";
import alarmDevices from "../../api/alarmDevices";
import { store } from "@/store";

// initial state
const state = {
  all: [],
};

// getters
const getters = {
  listAlarmDevices: (state, getters) => {
    return state.all;
  },
};

// mutations
const mutations = {
  reloadAlarmDevices(state, payload) {
    state.all = payload;
  },
};

// actions
const actions = {
  listAllAlarmDevices({ commit }, payload) {
    const results = getListAlarmDeviceByArea(payload);
    results.then(function (value) {
      commit("reloadAlarmDevices", value.arr_alarm_device);
      store.dispatch("areas/setAreaDetail", value.area);
      store.dispatch("floors/setFloorDetail", value.floor);
      store.dispatch("blocks/setBlockDetail", value.block);
    });
  },

  // results {
  //   "data": {
  //     "err": 0,
  //     "msg": "Added block successfully",
  //     "dt": null
  //   },
  //   "status": 200,
  //   "statusText": "OK",
  //   "detail": "error",
  //   "headers"
  //   "config"
  // }
  async addAlarmDevice(
    context,
    { areaId, alarmDeviceName, alarmDeviceSerialNumber }
  ) {
    const results = await alarmDevices.addAlarmDevice(
      areaId,
      alarmDeviceName,
      alarmDeviceSerialNumber
    );
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        if (results.data.err === 3) {
          results.data.msg =
            "Your company are having maximum camera! Please contact administrator to increase number of camera you can have.";
        }
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async updateAlarmDevice(
    context,
    { alarmDeviceId, alarmDeviceName, alarmDeviceSerialNumber }
  ) {
    const results = await alarmDevices.updateAlarmDevice(
      alarmDeviceId,
      alarmDeviceName,
      alarmDeviceSerialNumber
    );
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async updateStatusInBlock(context, { blockId }) {
    const results = await updateAlarmDeviceNormalStatusByBlock(blockId);
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async updateStatusInFloor(context, { floorId }) {
    const results = await updateAlarmDeviceNormalStatusByFloor(floorId);
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async updateStatusInArea(context, { areaId }) {
    const results = await updateAlarmDeviceNormalStatusByArea(areaId);
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async updateStatus(context, { alarmDeviceId }) {
    const results = await updateAlarmDeviceNormalStatus(alarmDeviceId);
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
