import { render, staticRenderFns } from "./CameraManagement.vue?vue&type=template&id=c2851e94&scoped=true"
import script from "./CameraManagement.vue?vue&type=script&lang=js"
export * from "./CameraManagement.vue?vue&type=script&lang=js"
import style0 from "./CameraManagement.vue?vue&type=style&index=0&id=c2851e94&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2851e94",
  null
  
)

export default component.exports