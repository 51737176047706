<template>
  <div id="appRoot">
    <template v-if="!$route.meta.public">
      <v-app id="inspire">
        <v-navigation-drawer
          :clipped="$vuetify.breakpoint.lgAndUp"
          v-model="drawer"
          fixed
          app
        >
          <v-list>
            <div v-for="item in items" :key="item.title">
              <v-list-tile :to="item.link" v-if="!item.hidden">
                <v-list-tile-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>{{
                  $t(`navDrawer.${item.value}`)
                }}</v-list-tile-content>
              </v-list-tile>
            </div>
          </v-list>
        </v-navigation-drawer>
        <!--color="#247748"-->
        <v-toolbar
          :clipped-left="$vuetify.breakpoint.lgAndUp"
          color="#FFF"
          light
          app
          fixed
        >
          <v-toolbar-side-icon
            class="ml-n1"
            @click.stop="drawer = !drawer"
          ></v-toolbar-side-icon>
          <v-toolbar-title
            style="width: 200px; cursor: pointer"
            class="ml-0"
            @click="onClickBrand()"
          >
            <v-layout align-center justify-center row fill-height>
              <v-img
                :src="require('@/assets/images/app_logo.png')"
                max-height="50"
                contain
              />
              <!--              <h4 class="hidden-sm-and-down d-inline">-->
              <!--                {{ title }}-->
              <!--              </h4>-->
            </v-layout>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            transition="slide-y-transition"
            bottom
            offset-y
            max-height="60vh"
          >
            <template v-slot:activator="{ on }">
              <v-badge
                v-model="isHaveNewNotifications"
                color="orange"
                class="mr-3 mt-3"
              >
                <template v-slot:badge>
                  <span>{{ countNewNotifications }}</span>
                </template>
                <v-btn
                  icon
                  large
                  class="mr-n2 mt-n2"
                  v-on="on"
                  :class="isHaveNewNotifications ? 'alert-glowing' : ''"
                >
                  <v-icon>notifications</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-list two-line>
              <template v-for="(item, index) in listNotifications">
                <v-list-tile
                  :key="index"
                  avatar
                  ripple
                  @click="goToAlarmDevicePage(item)"
                >
                  <v-list-tile-avatar tile>
                    <img :src="item.image_url" alt="notification_image" />
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                    <v-list-tile-sub-title
                      v-if="item.receive_state < 2"
                      class="text--primary"
                    >
                      {{ item.message }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-else>
                      {{ item.message }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-list-tile-action-text>{{
                      calculateTimeSinceSent(item.sent_date)
                    }}</v-list-tile-action-text>
                    <img
                      :src="
                        require('@/assets/images/mark_chat_unread_36dp_EA3323_FILL1_wght700_GRAD200_opsz40.svg')
                      "
                      alt=""
                      v-if="item.receive_state < 2"
                    />
                    <img
                      :src="
                        require('@/assets/images/mark_chat_read_36dp_2A9E00_FILL0_wght700_GRAD200_opsz40.svg')
                      "
                      alt=""
                      v-else
                    />
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider
                  v-if="index + 1 < listNotifications.length"
                  :key="'divider-' + index"
                ></v-divider>
              </template>
            </v-list>
          </v-menu>
          <language-switch></language-switch>
        </v-toolbar>
        <v-content>
          <router-view></router-view>
        </v-content>
      </v-app>
    </template>
    <template v-else>
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </template>
    <v-snackbar
      :timeout="5000"
      top
      center
      :color="snackbar.color"
      v-model="snackbar.show"
      class="mt-2"
    >
      {{ snackbar.text }}
      <v-btn dark flat @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import menu from "@/api/menu";
import AppEvents from "./event";

export default {
  data: function () {
    return {
      title: process.env.VUE_APP_TITLE,
      dialog: false,
      drawer: null,
      items: menu,
      snackbar: {
        show: false,
        text: "",
        color: "",
      },
    };
  },
  props: {
    source: String,
  },
  computed: {
    listNotifications() {
      return this.$store.state.user.notifications;
    },
    listNewNotifications() {
      return this.$store.state.user.notifications.filter(
        (noti) => noti.receive_state < 2
      );
    },
    countNewNotifications() {
      return this.listNewNotifications.length < 100 ? this.listNewNotifications.length : '99+'
    },
    isHaveNewNotifications() {
      return this.listNewNotifications.length > 0
    },
  },
  created() {
    AppEvents.forEach((item) => {
      this.$on(item.name, item.callback);
    });
    window.getApp = this;
  },
  methods: {
    onClickBrand() {
      this.$router.push("/").catch((err) => {
        // avoid NavigationDuplicated
        if (err.name !== "NavigationDuplicated") throw err;
      });
    },
    goToAlarmDevicePage(notificationModel) {
      this.$store.dispatch("user/setNotificationDetails", notificationModel);
    },
    calculateTimeSinceSent(sentDate) {
      let timeSinceSent = new Date(Date.now() - Date.parse(sentDate));
      if (timeSinceSent.getUTCDate() > 1)
        return timeSinceSent.getUTCDate() - 1 + "d";
      else if (timeSinceSent.getUTCHours() >= 1)
        return timeSinceSent.getUTCHours() + "H";
      else if (timeSinceSent.getUTCMinutes() >= 1)
        return timeSinceSent.getUTCMinutes() + "M";
      else return "just now";
    },
  },
};
</script>

<style lang="stylus">
@import './stylus/main'
</style>

<style lang="css">
@import "./stylus/offset-negative.css";
@import "./stylus/plyr.css";

#main {
  min-height: calc(100vh - 64px); /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px; /* height of your footer */
}

.mark_chat_unread {
  font-variation-settings: "FILL" 0, "wght" 600, "GRAD" 200, "opsz" 40;
}

.mark_chat_read {
  font-variation-settings: "FILL" 1, "wght" 600, "GRAD" 200, "opsz" 40;
}

.alert-glowing {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

.swal2-container-alert-notification {
  -webkit-animation: bg-glowing 1500ms infinite;
  -moz-animation: bg-glowing 1500ms infinite;
  -o-animation: bg-glowing 1500ms infinite;
  animation: bg-glowing 1500ms infinite;
}

@-webkit-keyframes bg-glowing {
  0% {
    background-color: #b2000077;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000A7;
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b2000077;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes bg-glowing {
  0% {
    background-color: #b2000077;
    -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000A7;
    -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b2000077;
    -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes bg-glowing {
  0% {
    background-color: #b2000077;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000A7;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b2000077;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes bg-glowing {
  0% {
    background-color: #b2000077;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000A7;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b2000077;
    box-shadow: 0 0 3px #b20000;
  }
}

@-webkit-keyframes glowing {
  0% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}
</style>
