<template>
  <v-container grid-list-lg class="pb-1">
    <div id="container">
      <v-layout row class="mx-0 mb-2">
        <v-card>
          <v-card-title id="card-title" primary-title class="py-1">
            <h3 class="mb-0">{{ detail.title }}</h3>
          </v-card-title>
          <v-card-text class="headline red--text">
            <h3>{{ detail.message }}</h3>
          </v-card-text>
          <v-card-text
            class="teal--text text--lighten-1 py-1"
            style="font-size: 1.5rem; font-weight: bold"
          >
            {{ getNotificationTime() }}
          </v-card-text>
          <v-card-text class="py-1">
            {{ getNotificationDate() }}
          </v-card-text>
        </v-card>
        <v-flex shrink>
          <v-img
            :src="detail.image_url ?? ''"
            min-height="200px"
            max-height="200px"
            min-width="400px"
            contain
            alt="notification_image"
          />
        </v-flex>
      </v-layout>
      <v-flex grow>
        <StreamingComponent :streamUrl="detail.video_url" height="65vh" />
      </v-flex>
    </div>
  </v-container>
</template>

<script>
import StreamingComponent from "./widgets/Streaming";

export default {
  name: "NotificationDetails",
  components: {
    StreamingComponent,
  },
  data() {
    return {};
  },
  computed: {
    detail() {
      return this.$store.state.user.notificationDetails;
    },
  },
  created() {},
  mounted() {
    let notificationID = this.$route.params.notificationID;
    if (notificationID) {
      this.$store.dispatch("user/getNotificationByID", { notificationID });
    }
  },
  methods: {
    getNotificationTime() {
      const tempDate = new Date(Date.parse(this.detail.sent_date));
      const hours = tempDate.getHours().toString().padStart(2, "0");
      const min = tempDate.getMinutes().toString().padStart(2, "0");
      const sec = tempDate.getSeconds().toString().padStart(2, "0");
      return `${hours}:${min}:${sec}`;
    },
    getNotificationDate() {
      const tempDate = new Date(Date.parse(this.detail.sent_date));
      const date = tempDate.getDate().toString().padStart(2, "0");
      const month = tempDate.getMonth().toString().padStart(2, "0");
      const year = tempDate.getFullYear();
      return `${date}/${month}/${year}`;
      // return new Date(Date.parse(this.detail.sent_date)).toLocaleDateString(
      //   "vi-VN",
      //   {
      //     year: "numeric",
      //     month: "2-digit",
      //     day: "2-digit",
      //     hour: "2-digit",
      //     hour12: false,
      //     minute: "2-digit",
      //     second: "2-digit",
      //   }
      // );
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}

.headline {
  text-align: center;
}
</style>
