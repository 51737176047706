import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import Swal from "sweetalert2";
import VModal from "vue-js-modal";

// Vuetify
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css"; // Ensure you are using css-loader

// Multi-Language
import { i18n } from "@/plugins/i18n";
import FlagIcon from "vue-flag-icon";
import LanguageSwitch from "@/components/LanguageSwitch";

// firebase
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { firebaseConfig } from "./constants";

Vue.config.productionTip = false;
Vue.use(FlagIcon);
Vue.use(Vuetify);
Vue.use(VModal, { componentName: "vue-js-modal" });

Vue.component("language-switch", LanguageSwitch);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
  created() {
    store.dispatch("user/fetchFingerprints");

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.log("Unable to get permission to notify.");
          throw new Error("Unable to get permission to notify.");
        }
        return navigator.serviceWorker.register("/firebase-messaging-sw.js");
      })
      .then((serviceWorkerRegistration) => {
        console.log("ServiceWorker registration successfully!");
        return getToken(messaging, { serviceWorkerRegistration });
      })
      .then((currentToken) => {
        store.dispatch("user/setFirebaseToken", currentToken);
      })
      .catch((err) => {
        console.log("Unable to get permission to notify.", err);
      });

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.onBackgroundMessage` handler.
    onMessage(messaging, (payload) => {
      window.focus();
      // console.log("Receive message from firebase!", payload);
      store.dispatch("user/getAllNotification");
      let audio = new Audio(require("@/assets/audio/fire_alarm_28s.mp3"));
      audio.play().catch((reason) => {
        console.log("Audio alarm couldn't play:", reason);
      });
      Swal.fire({
        title: payload.data.title,
        text: payload.data.message,
        imageUrl: payload.data.image_url,
        imageWidth: 400,
        // imageHeight: 200,
        imageAlt: "Alarm Image",
        confirmButtonText: "See Detection",
        confirmButtonColor: "#d33",
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: "swal2-container-alert-notification",
        },
      }).then((result) => {
        audio.pause();
        audio.currentTime = 0;
        if (result.isConfirmed) {
          store.dispatch("user/setNotificationDetails", payload.data);
        }
      });
    });

    navigator.serviceWorker.addEventListener("message", (event) => {
      if (!event.data.action) {
        return;
      }

      switch (event.data.action) {
        case "redirect-from-notificationclick":
          window.location.href = event.data.url;
          break;
        // no default
      }
    });
  },
}).$mount("#app");
