<template>
  <v-container id="container">
    <div id="main">
      <BreadcrumbsComponent :items="items" />
      <v-divider></v-divider>
      <v-layout row class="mb-2">
        <v-card>
          <v-card-title id="card-title" primary-title>
            <h3 class="headline mb-0">{{ detail.title }}</h3>
          </v-card-title>
          <v-card-text class="text--primary">
            {{ detail.message }}
          </v-card-text>
          <v-card-text>
            {{
              new Date(Date.parse(detail.sentDate)).toLocaleDateString(
                "vi-VN",
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  hour12: false,
                  minute: "2-digit",
                  second: "2-digit",
                }
              )
            }}
          </v-card-text>
        </v-card>

        <v-flex shrink>
          <v-img
            :src="detail.imageUrl"
            min-height="250px"
            max-height="250px"
            min-width="500px"
            contain
          />
        </v-flex>
      </v-layout>
      <v-flex grow>
        <StreamingComponent :streamUrl="detail.streamUrl" />
      </v-flex>
    </div>
    <footer-component></footer-component>
  </v-container>
</template>

<script>
import StreamingComponent from "./widgets/Streaming";
import FooterComponent from "./containers/Footer";
import BreadcrumbsComponent from "./containers/Breadcrumbs";

export default {
  name: "AlarmDeviceManagement",
  components: {
    StreamingComponent,
    FooterComponent,
    BreadcrumbsComponent,
  },
  data() {
    return {
      items: [
        {
          text: "Blocks",
          disabled: false,
          href: "/",
        },
        {
          text: "Floors",
          disabled: false,
          href:
            "/#/floor-management/" +
            this.$store.state.blocks.currentBlock.id_block,
        },
        {
          text: "Areas",
          disabled: false,
          href:
            "/#/area-management/" +
            this.$store.state.floors.currentFloor.id_floor,
        },
        {
          text: "AlarmDevices",
          disabled: false,
          href:
            "/#/alarm-device-list-management/" +
            this.$store.state.areas.currentArea.id_area,
        },
        {
          text: this.$store.state.alarmDevices.detail.alarmDeviceName,
          disabled: true,
          href: "/",
        },
      ],
    };
  },
  computed: {
    alarmDevice() {
      return this.$route.params.alarmDevice;
    },
    detail() {
      return this.$store.state.alarmDevices.detail;
    },
  },
  created() {},
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 100px;
}

.headline {
  text-align: center;
}
</style>
