<template>
  <v-container grid-list-lg id="main">
    <div id="container">
      <v-layout row>
        <v-flex grow>
          <BreadcrumbsComponent :items="items" />
        </v-flex>
        <v-flex shrink>
          <v-btn color="primary" @click="(isCreate = true), openAreaModal()"
            >Add Area</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <h1 id="area-title">
        {{ $t("manageInfoArea.areaList") }} - FLOOR {{ floorName }}
      </h1>
      <v-divider></v-divider>
      <v-layout wrap rows>
        <v-flex
          md3
          xs3
          v-for="area in areas"
          :key="area.index"
          class="text-md-center text-sx-center"
        >
          <!--Status = 1 => Safe | Status = 2 => Fall detected-->
          <v-card
            :id="area.status <= 1 ? area.id_area : cardId"
            @click.native="onClickAreas(area)"
          >
            <v-card-title class="primary">
              <v-layout align-center column>
                <div slot="label">
                  <h3 class="white--text">
                    {{ $t("manageInfoArea.area") }} {{ area.name_area }}
                  </h3>
                </div>
              </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <v-card flat tile class="d-flex">
              <v-img
                :src="require('@/assets/images/area-icon.svg')"
                aspect-ratio="1.1"
                contain
                class="grey lighten-2"
              >
                <v-layout slot="placeholder" align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-layout>
              </v-img>
            </v-card>
            <v-alert
              :value="true"
              color="success"
              :icon="area.status <= 1 ? 'check_circle' : 'priority_high'"
              outline
              @click.stop="openConfirmUpdateStatusModal(area)"
            >
              <!-- using click.stop to prevent firing click event on parent -->
              <v-btn
                @click.stop="(isCreate = false), openAreaModal(area)"
                absolute
                color="orange darken-1"
                class="white--text"
                fab
                small
                right
                top
              >
                <v-icon>edit</v-icon>
              </v-btn>
              {{ area.status <= 1 ? safeMessage : waringMessage }}
            </v-alert>
          </v-card>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </div>
    <footer-component id="footer"></footer-component>

    <vue-js-modal
      name="area-modal"
      @before-open="beforeOpenAreaModal"
      :clickToClose="false"
    >
      <v-container>
        <v-layout align-center column>
          <h1>
            {{
              isCreate ? "Add Area" : "Update Area " + areaOnEditing.areaName
            }}
          </h1>
        </v-layout>
        <v-form
          ref="areaForm"
          v-on="{ submit: isCreate ? addArea : updateArea }"
          onsubmit="return false;"
          v-model="isValid"
        >
          <v-text-field
            name="areaName"
            :label="labelAreaName"
            type="text"
            v-model="model.areaName"
            :rules="areaNameRules"
            class="mb-3"
          ></v-text-field>
          <v-layout justify-space-between row>
            <v-layout row>
              <v-btn
                color="red"
                @click="$modal.hide('area-modal')"
                :disabled="loading"
                >Close</v-btn
              >
              <v-btn
                color="warning"
                @click="$refs.areaForm.reset()"
                :disabled="loading"
                >Clear All</v-btn
              >
            </v-layout>
            <v-btn
              type="submit"
              color="success"
              :loading="loading"
              :disabled="!isValid"
              >Confirm</v-btn
            >
          </v-layout>
        </v-form>
      </v-container>
    </vue-js-modal>

    <vue-js-modal
      name="confirm-modal"
      height="auto"
      @before-open="beforeOpenAreaModal"
    >
      <v-container>
        <v-layout align-center column>
          <h1>{{ "Update Status" }}</h1>
        </v-layout>
        <v-card-text>
          {{ $t("text.confirmUpdateStatusInArea") }}
        </v-card-text>
        <v-layout justify-space-between row>
          <v-btn @click="$modal.hide('confirm-modal')" :disabled="loading"
            >Close</v-btn
          >
          <v-btn color="red" :loading="loading" @click="updateStatusInArea"
            >Confirm</v-btn
          >
        </v-layout>
      </v-container>
    </vue-js-modal>
  </v-container>
</template>

<script>
import FooterComponent from "./containers/Footer";
import BreadcrumbsComponent from "./containers/Breadcrumbs";
import Swal from "sweetalert2";

export default {
  name: "AreasManagement",
  components: {
    FooterComponent,
    BreadcrumbsComponent,
  },
  data() {
    return {
      safeMessage: "Everything is good.",
      waringMessage: "Fall Detected.",
      cardId: "card-area",
      loading: false,
      isValid: true,
      isCreate: true,
      model: {},
      areaOnEditing: {},
      labelAreaName: this.$t(`manageInfoArea.areaName`),
      areaNameRules: [(v) => !!v || this.$t(`rulesMessages.areaNameRequired`)],
    };
  },
  computed: {
    areas() {
      return this.$store.state.areas.all;
    },
    currentBlock() {
      return this.$store.state.blocks.currentBlock.id_block;
    },
    currentFloor() {
      return this.$route.params.floor;
    },
    floorName() {
      return this.$store.state.floors.currentFloor.name_floor;
    },
    items() {
      return [
        {
          text: "Blocks",
          disabled: false,
          href: "/#/location-management",
        },
        {
          text: "Floors",
          disabled: false,
          href:
            "/#/floor-management/" +
            this.$store.state.blocks.currentBlock.id_block,
        },
        {
          text: "Areas",
          disabled: true,
          href: "/",
        },
      ];
    },
  },
  created() {
    this.$store.dispatch("areas/listAreasByFloor", this.currentFloor);
  },
  methods: {
    onClickAreas(area) {
      this.$router.push({
        name: "AlarmDeviceListManagement",
        params: { area: area.id_area },
      });
    },

    openAreaModal(areaModel) {
      this.$modal.show("area-modal", { ...areaModel });
    },

    openConfirmUpdateStatusModal(areaModel) {
      if (areaModel.status > 1) {
        this.$modal.show("confirm-modal", {
          ...areaModel,
        });
      }
    },

    beforeOpenAreaModal(event) {
      this.model = event.params;
      this.areaOnEditing = event.params;
    },

    addArea() {
      this.loading = true;
      this.$store
        .dispatch("areas/addArea", {
          floorId: this.currentFloor,
          areaName: this.model.name_area,
        })
        .then((resolve) => {
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          this.$refs.areaForm.reset();
          this.$modal.hide("area-modal");
        })
        .catch((reject) => {
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Adding area failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
        });
    },

    updateArea() {
      this.loading = true;
      this.$store
        .dispatch("areas/updateArea", {
          areaId: this.model.id_area,
          areaName: this.model.name_area,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          console.log(resolve.data.msg);
          this.$refs.areaForm.reset();
          this.$modal.hide("area-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Updating area failed: " + reject.data.msg,
            "",
            ""
          );
          console.log("Updating area error:", reject);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
          // this.$refs.areaForm.reset()
          // this.$modal.hide('area-modal')
        });
    },

    updateStatusInArea() {
      this.loading = true;
      this.$store
        .dispatch("alarmDevices/updateStatusInArea", {
          areaId: this.model.id_area,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          this.$modal.hide("confirm-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Update Status In Area failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
        });
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 100px;
}

.disabled {
  color: grey;
  pointer-events: none;
}

#area-title {
  text-align: center;
  margin: 10px;
}

#card-area {
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}
</style>
