import auth from "../../api/auth";
import { store } from "../index";
import axios from "axios";

const state = {
  user_profile: {},
  JWT_Access: localStorage.getItem("JWT_Access"),
  JWT_Refresh: localStorage.getItem("JWT_Refresh"),
};

const getters = {};

const mutations = {
  setProfile(state, userProfile) {
    state.user_profile = userProfile;
  },

  updateAccessToken(state, newToken) {
    localStorage.setItem("JWT_Access", newToken);
    state.JWT_Access = newToken;

    // axios.defaults.withCredentials = true
    axios.defaults.headers.common["Authorization"] = "Bearer " + newToken;
  },

  removeAccessToken(state) {
    localStorage.setItem("JWT_Access", "");
    state.JWT_Access = "";
  },

  updateRefreshToken(state, newToken) {
    localStorage.setItem("JWT_Refresh", newToken);
    state.JWT_Refresh = newToken;
  },

  removeRefreshToken(state) {
    localStorage.setItem("JWT_Refresh", "");
    state.JWT_Refresh = "";
  },
};

// actions
const actions = {
  async login(context, { username, password }) {
    const results = await auth.login(username, password);
    return new Promise((resolve, reject) => {
      if (results.status === 200) {
        context.commit("updateAccessToken", results.data.access);
        context.commit("updateRefreshToken", results.data.refresh);
        resolve(results);
      } else {
        reject(results);
      }
    });
  },
  async logout(context) {
    const results = await auth.logout(
      store.state.user.fingerprint,
      state.JWT_Refresh
    );
    return new Promise((resolve, reject) => {
      if (results.err === 0) {
        context.commit("removeAccessToken");
        context.commit("removeRefreshToken");
        resolve(results);
      } else {
        reject(results);
      }
    });
  },
  async isLoggedIn(context) {
    const results = await auth.isLoggedIn();
    return new Promise((resolve, reject) => {
      if (results.err === 0) {
        context.commit("setProfile", results.dt["user_profile"]);
        resolve(results);
      } else {
        reject(results);
      }
    });
  },
  async refreshToken(context) {
    const results = await auth.refreshToken(state.JWT_Refresh);
    return new Promise((resolve, reject) => {
      if (results.status === 200) {
        context.commit("updateAccessToken", results.data.access);
        context.commit("updateRefreshToken", results.data.refresh);
        resolve(results);
      } else {
        reject(results);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
