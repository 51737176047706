<template>
  <v-breadcrumbs :items="getItems" class="py-1">
    <template slot="item" slot-scope="props">
      <a :href="props.item.href" :class="[props.item.disabled && 'disabled']">{{
        props.item.text.toUpperCase()
      }}</a>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "BreadcrumbsComponent",
  component: {},
  props: {
    items: Array,
  },
  computed: {
    getItems() {
      return this.$props.items;
    },
  },
};
</script>

<style scoped>
.disabled {
  color: grey;
  pointer-events: none;
}
</style>
