<template>
  <v-container grid-list-lg id="main">
    <div id="container">
      <v-layout row>
        <v-flex grow>
          <BreadcrumbsComponent :items="items" />
        </v-flex>
        <v-flex shrink>
          <v-btn color="primary" @click="(isCreate = true), openUserModal()"
            >Register User</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout row>
        <v-spacer></v-spacer>
        <v-flex shrink>
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="label.search"
            single-line
            hide-details
          />
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="listUsers"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:items="props">
          <td>{{ props.item.username }}</td>
          <td class="">{{ props.item.area_names }}</td>
          <td class="">{{ props.item.age }}</td>
          <td class="">{{ props.item.phone }}</td>
          <td class="">{{ genders[props.item.gender] }}</td>
          <td class="">{{ props.item.first_name }}</td>
          <td class="">{{ props.item.last_name }}</td>
          <td class="">{{ props.item.email }}</td>
          <td class="justify-center">
            <v-icon
              small
              class="mr-2"
              @click="(isCreate = false), openUserModal(props.item)"
            >
              edit
            </v-icon>
            <v-icon small @click="deleteUserModal(props.item)"> delete </v-icon>
          </td>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reload</v-btn>
        </template>
      </v-data-table>
      <v-divider></v-divider>
    </div>
    <footer-component id="footer"></footer-component>

    <vue-js-modal
      name="user-modal"
      @before-open="beforeOpenUserModal"
      :click-to-close="false"
      height="auto"
    >
      <v-container>
        <v-layout align-center column>
          <h1>{{ isCreate ? "Register User" : "Update User" }}</h1>
        </v-layout>
        <v-form
          ref="userForm"
          v-on="{ submit: isCreate ? registerUser : updateUser }"
          onsubmit="return false;"
          v-model="isValid"
        >
          <input
            autocomplete="off"
            name="hidden"
            type="search"
            style="display: none"
          />
          <v-text-field
            autocomplete="new-username"
            name="username"
            :label="label.username"
            type="text"
            v-model="model.username"
            @input="onchangeUsername"
            :rules="usernameRules"
            required
            :disabled="!isCreate"
          />
          <v-text-field
            autocomplete="new-password"
            name="password"
            :label="label.password"
            type="password"
            v-model="model.password"
            :rules="isCreate ? passwordRules : ''"
          />
          <v-text-field
            name="email"
            :label="label.email"
            type="text"
            v-model="model.email"
            :rules="emailRules"
            required
          />
          <v-text-field
            name="firstname"
            :label="label.firstname"
            type="text"
            v-model="model.first_name"
            :rules="firstnameRules"
            required
          />
          <v-text-field
            name="lastname"
            :label="label.lastname"
            type="text"
            v-model="model.last_name"
            :rules="lastnameRules"
            required
          />
          <v-text-field
            name="phone"
            :label="label.phone"
            type="text"
            v-model="model.phone"
          />
          <v-text-field
            name="age"
            :label="label.age"
            type="number"
            v-model="model.age"
          />
          <v-select
            name="gender"
            :label="label.gender"
            v-model="model.gender"
            item-text="text"
            item-value="value"
            :items="[
              { text: genders[1], value: 1 },
              { text: genders[0], value: 0 },
            ]"
          />

          <v-autocomplete
            name="area"
            v-model="model.area_ids_arr"
            :items="listAreas"
            box
            chips
            :label="label.area"
            item-text="name_area"
            item-value="id_area"
            multiple
            :rules="areaRules"
            required
          >
          </v-autocomplete>

          <v-layout justify-space-between row>
            <v-layout row>
              <v-btn
                color="red"
                @click="$modal.hide('user-modal')"
                :disabled="loading"
                >Close</v-btn
              >
              <v-btn
                color="warning"
                @click="clearAllUserForm()"
                :disabled="loading"
                >Clear All</v-btn
              >
            </v-layout>
            <v-btn
              type="submit"
              color="success"
              :loading="loading"
              :disabled="!isValid"
              >Confirm</v-btn
            >
          </v-layout>
        </v-form>
      </v-container>
    </vue-js-modal>

    <vue-js-modal
      name="confirm-modal"
      height="auto"
      @before-open="beforeOpenUserModal"
    >
      <v-container>
        <v-layout align-center column>
          <h1>{{ "Delete User" }}</h1>
        </v-layout>
        <v-card-text>
          {{ $t("text.confirmDelete") + " " + model.username + "?" }}
        </v-card-text>
        <v-layout justify-space-between row>
          <v-btn @click="$modal.hide('confirm-modal')" :disabled="loading"
            >Close</v-btn
          >
          <v-btn color="red" :loading="loading" @click="deactivateUser"
            >Confirm</v-btn
          >
        </v-layout>
      </v-container>
    </vue-js-modal>
  </v-container>
</template>

<script>
import FooterComponent from "./containers/Footer";
import BreadcrumbsComponent from "./containers/Breadcrumbs";
import Swal from "sweetalert2";

export default {
  name: "LocationsManagement",
  components: {
    FooterComponent,
    BreadcrumbsComponent,
  },
  data() {
    return {
      safeMessage: "Everything is good.",
      alarmMessage: "Alarm!!!!",
      dialog: false,
      items: [
        {
          text: "Users",
          disabled: true,
          href: "/",
        },
      ],
      loading: false,
      isValid: true,
      isCreate: true,
      model: {
        username: "",
      },
      userOnEditing: {},
      search: "",
      label: {
        username: this.$t(`manageUser.username`),
        password: this.$t(`manageUser.password`),
        email: this.$t(`manageUser.email`),
        phone: this.$t(`manageUser.phone`),
        age: this.$t(`manageUser.age`),
        gender: this.$t(`manageUser.gender`),
        firstname: this.$t(`manageUser.firstname`),
        lastname: this.$t(`manageUser.lastname`),
        area: this.$t("manageInfoArea.area"),
        search: this.$t("toolbar.search"),
      },
      usernameRules: [(v) => !!v || this.$t(`rulesMessages.usernameRequired`)],
      passwordRules: [(v) => !!v || this.$t(`rulesMessages.passwordRequired`)],
      emailRules: [(v) => !!v || this.$t(`rulesMessages.emailRequired`)],
      firstnameRules: [
        (v) => !!v || this.$t(`rulesMessages.firstnameRequired`),
      ],
      lastnameRules: [(v) => !!v || this.$t(`rulesMessages.lastnameRequired`)],
      areaRules: [
        (v) => v.length > 0 || this.$t(`rulesMessages.areaUserRequired`),
      ],
      headers: [
        { text: this.$t(`manageUser.username`), value: "username" },
        {
          text: this.$t("manageInfoArea.area"),
          value: "area",
          sortable: false,
        },
        { text: this.$t(`manageUser.age`), value: "age" },
        { text: this.$t(`manageUser.phone`), value: "phone" },
        { text: this.$t(`manageUser.gender`), value: "gender" },
        { text: this.$t(`manageUser.firstname`), value: "first_name" },
        { text: this.$t(`manageUser.lastname`), value: "last_name" },
        { text: this.$t(`manageUser.email`), value: "email" },
      ],
      genders: {
        0: this.$t(`manageUser.female`),
        1: this.$t(`manageUser.male`),
      },
    };
  },
  computed: {
    listUsers() {
      return this.$store.state.user.all;
    },
    listAreas() {
      return this.$store.state.user.areas;
    },
  },
  created() {
    // example: window.getApp.$emit('APP_NOTIFY_BY_SNACKBAR', true, 'green', 'Login successfully!', '/location-management', 'push')
    // Dispatch data
    this.$store.dispatch("user/listAllUsersInBlockManager");
    this.$store.dispatch("user/listAllAreasInBlockManager");
  },
  methods: {
    initialize() {
      this.$store.dispatch("user/listAllUsersInBlockManager");
    },

    openUserModal(userModel) {
      this.$modal.show("user-modal", { ...userModel });
    },

    deleteUserModal(userModel) {
      this.$modal.show("confirm-modal", { ...userModel });
    },

    beforeOpenUserModal(event) {
      this.model = event.params;
      this.userOnEditing = event.params;
    },

    clearAllUserForm() {
      if (!this.isCreate) {
        var username = this.model.username;
        this.$refs.userForm.reset();
        this.$nextTick(() => {
          this.model.username = username;
        });
      } else {
        this.$refs.userForm.reset();
      }
    },

    registerUser() {
      this.$store
        .dispatch("user/registerUser", this.model)
        .then((resolve) => {
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            ""
          );
          this.$refs.userForm.reset();
          this.$modal.hide("user-modal");
          this.initialize();
        })
        .catch((reject) => {
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Adding user failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
        });
    },

    updateUser() {
      this.loading = true;
      this.$store
        .dispatch("user/updateUser", this.model)
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            ""
          );
          this.$refs.userForm.reset();
          this.$modal.hide("user-modal");
          this.initialize();
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Updating user failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
        });
    },

    deactivateUser() {
      this.loading = true;
      this.$store
        .dispatch("user/deactivateUser", { username: this.model.username })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            ""
          );
          this.$modal.hide("confirm-modal");
          this.initialize();
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Delete user failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
        });
    },

    onchangeUsername() {
      this.$nextTick(() => {
        this.model.username = this.model.username.replaceAll(/\s+/g, "");
      });
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 100px;
}

.disabled {
  color: grey;
  pointer-events: none;
}

#user-title {
  text-align: center;
  margin: 10px;
  font-size: 35px;
}
</style>
