import Vue from "vue";
import VueRouter from "vue-router";

import PageLogin from "@/components/PageLogin";
import LocationManagement from "@/components/LocationManagement";
import FloorManagement from "@/components/FloorManagement";
import AreaManagement from "@/components/AreaManagement";
import CameraListManagement from "@/components/CameraListManagement";
import CameraManagement from "@/components/CameraManagement";
import UserManagement from "@/components/UserManagement";
import AlarmDeviceListManagement from "@/components/AlarmDeviceListManagement";
import AlarmDeviceManagement from "@/components/AlarmDeviceManagement";
import NotificationDetails from "@/components/NotificationDetails";

import { store } from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  base: "/",
  mode: "hash",
  linkActiveClass: "active",
  routes: [
    {
      path: "/login",
      name: "Login",
      meta: {
        public: true,
      },
      component: PageLogin,
    },
    {
      path: "/logout",
      name: "Logout",
      meta: {
        public: true,
      },
    },
    {
      path: "/",
      meta: {},
      name: "Root",
      redirect: {
        name: "LocationManagement",
      },
    },
    {
      path: "/location-management",
      name: "LocationManagement",
      component: LocationManagement,
    },
    {
      path: "/floor-management/:block",
      name: "FloorManagement",
      component: FloorManagement,
    },
    {
      path: "/area-management/:floor",
      name: "AreaManagement",
      component: AreaManagement,
    },
    {
      path: "/camera-list-management/:area",
      name: "CameraListManagement",
      component: CameraListManagement,
    },
    {
      path: "/camera-management/:camera",
      name: "CameraManagement",
      component: CameraManagement,
    },
    {
      path: "/user-management",
      name: "UserManagement",
      component: UserManagement,
    },
    {
      path: "/alarm-device-list-management/:area",
      name: "AlarmDeviceListManagement",
      component: AlarmDeviceListManagement,
    },
    {
      path: "/alarm-device-management/:alarmDevice",
      name: "AlarmDeviceManagement",
      component: AlarmDeviceManagement,
    },
    {
      path: "/notification/:notificationID?",
      name: "NotificationDetails",
      component: NotificationDetails,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name === "Login") {
    next();
  } else if (to.name === "Logout") {
    store
      .dispatch("auth/logout")
      .then((resolve) => {})
      .catch((reject) => {
        console.log("Logout error:", reject.msg);
      });
    next({ name: "Login" });
  } else {
    store
      .dispatch("auth/isLoggedIn")
      .then((resolve) => {
        if (resolve.dt["is_logged_in"] === 1) {
          store.dispatch("user/getAllNotification");
          next();
        } else {
          next({ name: "Login" });
        }
      })
      .catch((reject) => {
        if (reject.status === 401) {
          store
            .dispatch("auth/refreshToken")
            .then((resolve) => {
              console.log("RefreshToken successfully:", resolve.msg);
            })
            .catch((reject) => {
              console.log("RefreshToken error:", reject.msg);
              next({ name: "Login" });
            });
        } else if (reject.status !== 200) {
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Server error: " + reject.data,
            "",
            ""
          );
        }
        next();
      });
  }
});

export default router;
