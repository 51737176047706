export const DEFAULT_LANGUAGE = "vn";
export const FALLBACK_LANGUAGE = "vn";
export const SUPPORTED_LANGUAGES = ["en", "vn", "ja"];

export const firebaseConfig = {
  apiKey: "AIzaSyC8KTl4nOzIewW2qLkoBFIObeYVEqynT8Y",
  authDomain: "detectionnotification-ddaff.firebaseapp.com",
  projectId: "detectionnotification-ddaff",
  storageBucket: "detectionnotification-ddaff.appspot.com",
  messagingSenderId: "499785489588",
  appId: "1:499785489588:web:a8c3816a0c5fe842c98be1",
  measurementId: "G-BP18KPH9XX",
};
// Web Push certificates Key pair
export const PublicVapidKey = "";
