import user from "../../api/user";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { store } from "@/store";
import router from "@/router";

const state = {
  all: [],
  areas: [],
  notifications: [],
  notificationDetails: {},
  fingerprint: "",
  firebaseToken: "",
};

const getters = {};

const mutations = {
  reloadUsers(state, payload) {
    state.all = payload;
  },

  reloadAreas(state, payload) {
    state.areas = payload;
  },

  reloadNotifications(state, payload) {
    state.notifications = payload;
  },

  setFirebaseToken(state, firebaseToken) {
    state.firebaseToken = firebaseToken;
  },

  setFingerprint(state, fingerprint) {
    state.fingerprint = fingerprint;
  },

  setNotificationDetails(state, item) {
    state.notificationDetails = item;
  },
};

// actions
const actions = {
  setFirebaseToken({ commit }, firebaseToken) {
    commit("setFirebaseToken", firebaseToken);
  },

  async updateFirebaseToken(
    context,
    { macAddress, deviceName, appToken, appName }
  ) {
    const results = await user.updateFirebaseToken(
      macAddress,
      deviceName,
      appToken,
      appName
    );
    return new Promise((resolve, reject) => {
      if (results.err === 0) {
        resolve(results);
      } else {
        reject(results);
      }
    });
  },

  fetchFingerprints({ commit }) {
    return new Promise((resolve, reject) => {
      if (window.requestIdleCallback) {
        requestIdleCallback(function () {
          // Initialize the agent at application startup.
          const fpPromise = FingerprintJS.load();

          // Get the visitor identifier when you need it.
          fpPromise
            .then((fp) => fp.get())
            .then((result) => commit("setFingerprint", result.visitorId));
        });
      } else {
        setTimeout(function () {
          // Initialize the agent at application startup.
          const fpPromise = FingerprintJS.load();

          // Get the visitor identifier when you need it.
          fpPromise
            .then((fp) => fp.get())
            .then((result) => commit("setFingerprint", result.visitorId));
        }, 500);
      }
    });
  },

  listAllUsersInBlockManager({ commit }) {
    const results = user.getListAllUsersInBlockManager();
    results.then(function (value) {
      commit("reloadUsers", value);
    });
  },

  listAllAreasInBlockManager({ commit }) {
    const results = user.getListAllAreasInBlockManager();
    results.then(function (value) {
      commit("reloadAreas", value);
    });
  },

  async registerUser(context, userModel) {
    const results = await user.registerUser(
      userModel.username,
      userModel.password,
      userModel.email,
      userModel.last_name,
      userModel.first_name,
      userModel.age,
      userModel.gender,
      userModel.phone,
      userModel.avatar,
      userModel.area_ids_arr
    );
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async updateUser(context, userModel) {
    const results = await user.updateUser(
      userModel.username,
      userModel.password,
      userModel.email,
      userModel.last_name,
      userModel.first_name,
      userModel.age,
      userModel.gender,
      userModel.phone,
      userModel.avatar,
      userModel.area_ids_arr
    );
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async deactivateUser(context, { username }) {
    const results = await user.deactivateUser(username);
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async getAllNotification({ commit }) {
    const results = user.getAllNotification();
    results.then(function (value) {
      commit("reloadNotifications", value);
    });
  },

  async markNotificationRead({ commit }, { alarmDevice, sentDate }) {
    const results = await user.markNotificationRead(alarmDevice, sentDate);
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async getNotificationByID({ commit }, { notificationID }) {
    const results = user.getNotificationByID(notificationID);
    results.then(function (value) {
      commit("setNotificationDetails", value);
    });
  },

  // notificationModel if sent by firebase will have all properties with type string
  setNotificationDetails({ commit }, notificationModel) {
    if (Number.parseInt(notificationModel.receive_state) < 2) {
      store
        .dispatch("user/markNotificationRead", {
          alarmDevice: notificationModel.id_alarm_device,
          sentDate: notificationModel.sent_date,
        })
        .then(() => {
          store.dispatch("user/getAllNotification");
        });
    }
    commit("setNotificationDetails", notificationModel);
    router.push({ name: "NotificationDetails" }).catch((err) => {
      // avoid NavigationDuplicated
      if (err.name !== "NavigationDuplicated") throw err;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
