import axios from "axios";

const BASE_URL = process.env.VUE_APP_SERVER_API;

export default {
  /*
   * Function: addAlarmDevice
   * Params: areaId, alarmDeviceName, serialNumber
   * */
  async addAlarmDevice(areaId, alarmDeviceName, serialNumber) {
    const url = `${BASE_URL}/api/v1/alarm_device/add`;
    let res = [];
    await axios
      .post(
        url,
        {
          id_area: areaId,
          serial_number: serialNumber,
          name_alarm_device: alarmDeviceName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },

  /*
   * Function: updateAlarmDevice
   * Params: alarmDeviceId, alarmDeviceName, serialNumber
   * */
  async updateAlarmDevice(alarmDeviceId, alarmDeviceName, serialNumber) {
    const url = `${BASE_URL}/api/v1/alarm_device/update`;
    let res = [];
    await axios
      .post(
        url,
        {
          id_alarm_device: alarmDeviceId,
          serial_number: serialNumber,
          name_alarm_device: alarmDeviceName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },
};
