import axios from "axios";

const BASE_URL = process.env.VUE_APP_SERVER_API;

export default {
  /*
   * Function: addCamera
   * Params: cameraName
   * */
  async addCamera(areaId, cameraName, streamUrl) {
    const url = `${BASE_URL}/api/v1/camera/add`;
    let res = [];
    await axios
      .post(
        url,
        {
          id_area: areaId,
          stream_url: streamUrl,
          name_camera: cameraName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },

  /*
   * Function: addCamera
   * Params: cameraName
   * */
  async updateCamera(cameraId, cameraName, streamUrl) {
    const url = `${BASE_URL}/api/v1/camera/update`;
    let res = [];
    await axios
      .post(
        url,
        {
          id_camera: cameraId,
          stream_url: streamUrl,
          name_camera: cameraName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },
};
