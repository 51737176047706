import Vue from "vue";
import Vuex from "vuex";
import blocks from "./modules/blocks";
import createLogger from "vuex/dist/logger";
import floors from "./modules/floors";
import areas from "./modules/areas";
import cameras from "./modules/cameras";
import positions from "./modules/positions";
import user from "./modules/user";
import auth from "./modules/auth";
import alarmDevices from "./modules/alarmDevices";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
export const store = new Vuex.Store({
  modules: {
    blocks,
    floors,
    areas,
    cameras,
    positions,
    user,
    auth,
    alarmDevices,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
