<template>
  <video
    id="player"
    controls
    crossorigin
    playsinline
    :style="'height: ' + height"
  >
    <source :src="getUrl" type="video/mp4" />
  </video>
</template>

<script>
import Plyr from "plyr";

export default {
  name: "StreamingComponent",
  data() {
    return {
      player: {},
    };
  },
  props: {
    height: String,
    streamUrl: String,
  },
  computed: {
    getUrl() {
      return this.$props.streamUrl;
    },
  },
  mounted() {
    this.player = new Plyr("#player");
  },
  beforeUnmount() {
    try {
      this.player.destroy();
    } catch (e) {
      console.error(e);
    }
  },
  watch: {
    streamUrl: function (val) {
      this.$el.src = val;
    },
  },
};
</script>
