<template>
  <v-container grid-list-lg id="main">
    <div id="container">
      <v-layout row>
        <v-flex grow>
          <BreadcrumbsComponent :items="items" />
        </v-flex>
        <v-flex shrink>
          <v-btn color="primary" @click="(isCreate = true), openBlockModal()"
            >Add Block</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <h1 id="block-title">Fall Detection System</h1>
      <v-divider></v-divider>
      <v-layout wrap rows>
        <v-flex
          md3
          xs2
          v-for="block in blocks"
          :key="block.index"
          class="text-md-center text-sx-center"
        >
          <!--Status = 1 => Safe | Status = 2 => Fall detected-->
          <v-card
            :id="block.status <= 1 ? block.id_block : cardId"
            :class="block.status <= 1 ? '' : 'alert-glowing'"
            @click.native="onClickBlock(block)"
          >
            <v-card-title class="primary">
              <v-layout align-center column>
                <div slot="label">
                  <h3 class="white--text">
                    {{ $t("manageInfoArea.block") }} {{ block.name_block }}
                  </h3>
                </div>
              </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <v-card flat tile class="d-flex">
              <v-img
                :src="require('@/assets/images/block-icon.svg')"
                aspect-ratio="1.1"
                contain
                class="grey lighten-2"
              >
                <v-layout slot="placeholder" align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-layout>
              </v-img>
            </v-card>
            <v-alert
              :id="'msg' + block.id_block"
              :value="true"
              color="success"
              :icon="block.status <= 1 ? 'check_circle' : 'priority_high'"
              outline
              @click.stop="openConfirmUpdateStatusModal(block)"
            >
              <!-- using click.stop to prevent firing click event on parent -->
              <v-btn
                @click.stop="(isCreate = false), openBlockModal(block)"
                absolute
                color="orange darken-1"
                class="white--text"
                fab
                small
                right
                top
              >
                <v-icon>edit</v-icon>
              </v-btn>
              {{ block.status <= 1 ? safeMessage : alarmMessage }}
            </v-alert>
          </v-card>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </div>
    <footer-component id="footer"></footer-component>

    <vue-js-modal
      name="block-modal"
      @before-open="beforeOpenBlockModal"
      :click-to-close="false"
    >
      <v-container>
        <v-layout align-center column>
          <h1>
            {{
              isCreate
                ? "Add Block"
                : "Update Block " + blockOnEditing.name_block
            }}
          </h1>
        </v-layout>
        <v-form
          ref="blockForm"
          v-on="{ submit: isCreate ? addBlock : updateBlock }"
          onsubmit="return false;"
          v-model="isValid"
        >
          <v-text-field
            name="blockName"
            :label="labelBlockName"
            type="text"
            v-model="model.name_block"
            :rules="blockNameRules"
          ></v-text-field>
          <v-layout justify-space-between row>
            <v-layout row>
              <v-btn
                color="red"
                @click="$modal.hide('block-modal')"
                :disabled="loading"
                >Close</v-btn
              >
              <v-btn
                color="warning"
                @click="$refs.blockForm.reset()"
                :disabled="loading"
                >Clear All</v-btn
              >
            </v-layout>
            <v-btn
              type="submit"
              color="success"
              :loading="loading"
              :disabled="!isValid"
              >Confirm</v-btn
            >
          </v-layout>
        </v-form>
      </v-container>
    </vue-js-modal>

    <vue-js-modal
      name="confirm-modal"
      height="auto"
      @before-open="beforeOpenBlockModal"
    >
      <v-container>
        <v-layout align-center column>
          <h1>{{ "Update Status" }}</h1>
        </v-layout>
        <v-card-text>
          {{ $t("text.confirmUpdateStatusInBlock") }}
        </v-card-text>
        <v-layout justify-space-between row>
          <v-btn @click="$modal.hide('confirm-modal')" :disabled="loading"
            >Close</v-btn
          >
          <v-btn color="red" :loading="loading" @click="updateStatusInBlock"
            >Confirm</v-btn
          >
        </v-layout>
      </v-container>
    </vue-js-modal>
  </v-container>
</template>

<script>
import FooterComponent from "./containers/Footer";
import BreadcrumbsComponent from "./containers/Breadcrumbs";
import Swal from "sweetalert2";

export default {
  name: "LocationsManagement",
  components: {
    FooterComponent,
    BreadcrumbsComponent,
  },
  data() {
    return {
      safeMessage: "Everything is good.",
      alarmMessage: "Alarm!!!!",
      cardId: "card-block",
      alarmBlocks: [],
      dialog: false,
      items: [
        {
          text: "Blocks",
          disabled: true,
          href: "/",
        },
      ],
      loading: false,
      isValid: true,
      isCreate: true,
      model: {},
      blockOnEditing: {},
      labelBlockName: this.$t(`manageInfoArea.blockName`),
      blockNameRules: [
        (v) => !!v || this.$t(`rulesMessages.blockNameRequired`),
      ],
    };
  },
  computed: {
    blocks() {
      return this.$store.state.blocks.all;
    },
  },
  created() {
    // example: window.getApp.$emit('APP_NOTIFY_BY_SNACKBAR', true, 'green', 'Login successfully!', '/location-management', 'push')
    // Dispatch data
    this.$store.dispatch("blocks/listAllBlocks");
  },
  methods: {
    onClickBlock(block) {
      this.$router.push({
        name: "FloorManagement",
        params: { block: block.id_block },
      });
    },

    openBlockModal(blockModel) {
      this.$modal.show("block-modal", {
        ...blockModel,
      });
    },

    openConfirmUpdateStatusModal(blockModel) {
      if (blockModel.status > 1) {
        this.$modal.show("confirm-modal", {
          ...blockModel,
        });
      }
    },

    beforeOpenBlockModal(event) {
      this.model = event.params;
      this.blockOnEditing = event.params;
    },

    addBlock() {
      this.$store
        .dispatch("blocks/addBlock", {
          blockName: this.model.name_block,
        })
        .then((resolve) => {
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          console.log(resolve.data.msg);
          this.$refs.blockForm.reset();
          this.$modal.hide("block-modal");
        })
        .catch((reject) => {
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Adding block failed: " + reject.data.msg,
            "",
            ""
          );
          console.log("Adding block error:", reject);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
        });
    },

    updateBlock() {
      this.loading = true;
      this.$store
        .dispatch("blocks/updateBlock", {
          blockId: this.model.id_block,
          blockName: this.model.name_block,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          console.log(resolve.data.msg);
          this.$refs.blockForm.reset();
          this.$modal.hide("block-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Updating block failed: " + reject.data.msg,
            "",
            ""
          );
          console.log("Updating block error:", reject);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
          // this.$refs.blockForm.reset()
          // this.$modal.hide('block-modal')
        });
    },

    updateStatusInBlock() {
      this.loading = true;
      this.$store
        .dispatch("alarmDevices/updateStatusInBlock", {
          blockId: this.model.id_block,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          this.$modal.hide("confirm-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Update Status In Block failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
        });
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 100px;
}

.disabled {
  color: grey;
  pointer-events: none;
}

#block-title {
  text-align: center;
  margin: 10px;
  font-size: 35px;
}

#card-block {
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
</style>
